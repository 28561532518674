<template>
  <div>
    <h3>Add Testimonial</h3>
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  v-model="user.name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Image</label>
                <input
                  type="file"
                  placeholder="Image"
                  class="form-control"
                  ref="testimonial_avatar"
                />
              </div>
              <div class="form-group">
                <label>Content</label>
                <textarea rows="4" class="form-control" placeholder="Content" v-model="user.content"></textarea>
              </div>
              <div class="form-group">
                <label>Rating</label>
                <input-rating v-model="user.rating" />
              </div>
              <div class="form-group">
                <label>Type</label>
                <select v-model="user.type" class="form-control">
                  <option value="website">General</option>
                  <option value="movers">Movers</option>
                  <option value="field-assistants">Field Assistants</option>
                  <option value="seller-plans">Seller Plans</option>
                  <option value="painting-and-cleaning">Painting And Cleaning</option>
                  <option value="legal-and-surveying">Legal And Surveying</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: { rating: 0, type: 'website' },
    };
  },

  methods: {
    submit() {
      let data = new FormData
      data.append('name', this.user.name)
      data.append('content', this.user.content)
      data.append('rating', this.user.rating)
      data.append('type', this.user.type)
      data.append('avatar', this.$refs.testimonial_avatar.files[0])

      this.$axios.post('/api/v1/admin/testimonials', data).then(() => {
        this.$router.push({ name: 'admin.testimonials.index' })
      })
    }
  }
};
</script>
